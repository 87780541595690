"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetQuestionState = exports.getDocumentData = exports.submitDocument = exports.answerDocument = exports.saveFormsQuestions = exports.getFormQuestion = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const network_1 = __importStar(require("../../utils/network"));
const initialState = {
    survey_question: {
        id: '',
        maximum_score: 0,
        minimum_score: 0,
        position: '',
        question: '',
        question_type: 0,
    },
};
exports.getFormQuestion = (0, toolkit_1.createAsyncThunk)('nps/survey', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/feedback/nps/${payload.question_id}/${payload.loan_id}/`, { clientBody: {}, method: 'GET', token: auth.token }, { rejectWithValue });
});
exports.saveFormsQuestions = (0, toolkit_1.createAsyncThunk)('nps/survey/save', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/feedback/nps/${payload.question_id}/${payload.loan_id}/`, { clientBody: { answer: payload.answer }, method: 'POST', token: auth.token }, { rejectWithValue });
});
exports.answerDocument = (0, toolkit_1.createAsyncThunk)('survey/accept_decline', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/feedback/${payload.document_type}/accept_decline/`, {
        clientBody: { decision: payload.decision, loan_application_id: payload.loan_application_id },
        method: 'POST',
        token: auth.token,
    }, { rejectWithValue });
});
exports.submitDocument = (0, toolkit_1.createAsyncThunk)('survey/uploadFile', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/feedback/upload/${payload.document_type}/`, { clientBody: payload.body, method: 'POST', token: auth.token }, { rejectWithValue }, { 'Accept': 'application/json', 'Content-Type': 'multipart/form-data' });
});
exports.getDocumentData = (0, toolkit_1.createAsyncThunk)('survey/accept_decline', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/feedback/${payload.document_type}/accept_decline/`, { clientBody: {}, method: 'GET', token: auth.token }, { rejectWithValue });
});
const QuestionsSlice = (0, toolkit_1.createSlice)({
    name: 'question',
    initialState,
    reducers: {
        resetQuestionState() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(exports.getFormQuestion.fulfilled, (state, { payload }) => {
            const newState = { ...state };
            newState.survey_question = { ...payload };
            return newState;
        });
    },
});
exports.resetQuestionState = QuestionsSlice.actions.resetQuestionState;
exports.default = QuestionsSlice.reducer;
