"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.institutionsChoices = void 0;
const abMicrofinance = require('../assets/images/ng-bank-logos/ab_microfinance.jpg');
const access = require('../assets/images/ng-bank-logos/access.png');
const accion = require('../assets/images/ng-bank-logos/accion_microfinance.png');
const alat = require('../assets/images/ng-bank-logos/ALAT_Wema_Logo.png');
const crossover = require('../assets/images/ng-bank-logos/crossover.jpg');
const diamond = require('../assets/images/ng-bank-logos/diamond.jpg');
// const enterprise = require('../assets/images/ng-bank-logos/enterprise.jpg');
const fidelity = require('../assets/images/ng-bank-logos/fidelity.png');
const gtb = require('../assets/images/ng-bank-logos/gtb.png');
const jaiz = require('../assets/images/ng-bank-logos/jaiz.png');
// const mainstreet = require('../assets/images/ng-bank-logos/mainstreet.jpg');
const providus = require('../assets/images/ng-bank-logos/providus.png');
const sc = require('../assets/images/ng-bank-logos/sc.png');
const sterling = require('../assets/images/ng-bank-logos/sterling.png');
const union = require('../assets/images/ng-bank-logos/union.jpg');
const wema = require('../assets/images/ng-bank-logos/wema.png');
// const ti = require('../assets/images/ng-bank-logos/ti.png');
const eco = require('../assets/images/ng-bank-logos/eco.png');
const fcmb = require('../assets/images/ng-bank-logos/fcmb.png');
const firstbank = require('../assets/images/ng-bank-logos/firstbank.png');
const hb = require('../assets/images/ng-bank-logos/hb.png');
const keystone = require('../assets/images/ng-bank-logos/keystone.png');
const kuda = require('../assets/images/ng-bank-logos/Kuda_Bank_Logo.png');
const polaris = require('../assets/images/ng-bank-logos/polaris.jpg');
// const rolez = require('../assets/images/ng-bank-logos/rolez.png');
const stanbic = require('../assets/images/ng-bank-logos/stanbic.png');
const uba = require('../assets/images/ng-bank-logos/uba.jpg');
const unity = require('../assets/images/ng-bank-logos/unity.png');
const zenith = require('../assets/images/ng-bank-logos/zenith.png');
const royal = require('../assets/images/ng-bank-logos/royal_microfinance.jpg');
const rubies = require('../assets/images/ng-bank-logos/rubies.jpg');
const renmoney = require('../assets/images/ng-bank-logos/renmoney.jpg');
const onefi = require('../assets/images/ng-bank-logos/onefi.png');
const mutualMicro = require('../assets/images/ng-bank-logos/mutual_microfinance.png');
const citi = require('../assets/images/ng-bank-logos/citi.png');
const bankChoices = [
    { value: '', label: '' },
    { value: '044150291', label: 'Access Bank', image: access },
    { value: '035000000', label: 'Alat by Wema', image: alat },
    { value: '023150005', label: 'Citi Bank', image: citi },
    { value: '063150162', label: 'Diamond Bank', image: diamond },
    { value: '050150010', label: 'Ecobank Bank', image: eco },
    { value: '070150003', label: 'Fidelity Bank', image: fidelity },
    { value: '011151003', label: 'First Bank of Nigeria', image: firstbank },
    { value: '214150018', label: 'First City Monument Bank', image: fcmb },
    { value: '058152052', label: 'GTBank Plc', image: gtb },
    { value: '030150014', label: 'Heritage', image: hb },
    { value: '082150017', label: 'Keystone Bank', image: keystone },
    { value: '090267000', label: 'Kuda Bank', image: kuda },
    { value: '076151365', label: 'Polaris Bank', image: polaris },
    { value: '101152019', label: 'Providus Bank', image: providus },
    { value: '000000001', label: 'Rubies Bank', image: rubies },
    { value: '221159522', label: 'StanbicIBTC Bank', image: stanbic },
    { value: '068150015', label: 'StandardChartered', image: sc },
    { value: '232150016', label: 'Sterling Bank', image: sterling },
    { value: '032154568', label: 'Union Bank', image: union },
    { value: '033152048', label: 'United Bank for Africa', image: uba },
    { value: '215082334', label: 'Unity Bank', image: unity },
    { value: '035150103', label: 'Wema Bank', image: wema },
    { value: '057150013', label: 'Zenith Bank', image: zenith },
    { value: '301080020', label: 'Jaiz Bank', image: jaiz },
];
exports.institutionsChoices = [
    { value: 0, label: 'Other' },
    { value: 1, label: 'AB Microfinance Bank Limited', image: abMicrofinance },
    { value: 2, label: 'Access Bank Plc', image: access },
    { value: 3, label: 'Accion Microfinance Bank Limited', image: accion },
    { value: 23, label: 'Alat by Wema', image: alat },
    { value: 4, label: 'Crossover Microfinance Bank Limited', image: crossover },
    { value: 5, label: 'Ecobank Nigeria Plc', image: eco },
    { value: 6, label: 'FBN Microfinance Bank Limited', image: firstbank },
    { value: 7, label: 'Fidelity Bank Plc', image: fidelity },
    { value: 8, label: 'First Bank of Nigeria Limited', image: firstbank },
    { value: 9, label: 'First City Monument Bank Limited', image: fcmb },
    { value: 10, label: 'Guaranty Trust Bank Plc', image: gtb },
    { value: 24, label: 'Kuda Bank', image: kuda },
    { value: 11, label: 'Heritage Banking Company Limited', image: hb },
    {
        value: 12,
        label: 'Mutual Benefit Microfinance Bank Limited',
        image: mutualMicro,
    },
    { value: 13, label: 'OneFi', image: onefi },
    { value: 14, label: 'Providus Bank Limited', image: providus },
    {
        value: 15,
        label: 'RenMoney (Rencredit) Microfinance Bank Limited',
        image: renmoney,
    },
    {
        value: 16,
        label: 'Royal Exchange Microfinance Bank Limited',
        image: royal,
    },
    { value: 25, label: 'Rubies Bank', image: rubies },
    { value: 17, label: 'Stanbic IBTC Bank Plc', image: stanbic },
    { value: 18, label: 'Standard Chartered', image: sc },
    { value: 19, label: 'Sterling Bank Plc', image: sterling },
    { value: 20, label: 'Union Bank of Nigeria Plc', image: union },
    { value: 21, label: 'United Bank for Africa Plc', image: uba },
    { value: 22, label: 'Zenith Bank Plc', image: zenith },
];
exports.default = bankChoices;
