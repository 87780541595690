"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VER_NAME = exports.COUNTRY_CODE = exports.LINKING_PREFIXES = exports.OKRA_CLIENT_KEY = exports.OKRA_KEY = exports.MONO_KEY = exports.MIXPANEL_TOKEN = exports.BACKEND_URL = void 0;
const production = process.env.NODE_ENV === 'production';
exports.BACKEND_URL = production ? 'https://loanapp.lendigo.ng' : 'http://localhost:8000';
exports.MIXPANEL_TOKEN = production ? '2eaf078762465e165c11b31ef90b531f' : '5ca2e4bc19777af037214898db59740c';
exports.MONO_KEY = production ? 'live_pk_fj2fUuJ6JaO6pUXRlxqB' : 'test_pk_fZUYzwrefxUSp05OHDSM';
exports.OKRA_KEY = production ? '3eb8ff1f-485a-56c4-b8e3-cb012b30acce' : '147249fd-6fb2-5b82-987e-b6575d4be40d';
exports.OKRA_CLIENT_KEY = '5f1ec335692bd00fee6b5821';
exports.LINKING_PREFIXES = production ? ['https://app.lendigo.ng/', 'app.lendigo.ng', 'lendigo://'] : ['http://localhost:8080/', 'localhost:8080', 'lendigo://'];
exports.COUNTRY_CODE = '+234';
exports.VER_NAME = '2.1';
