"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.screens = exports.RemitaConnectionLink = exports.MyBankStatementLink = exports.AcceptDeclineLink = exports.SurveyLink = exports.PartnerOfferLetterLink = exports.PartnerConnectBankAccountLink = exports.PartnerBankAccountInformationLink = exports.ProfileLink = exports.UploadBankStatementLink = exports.LoanApplicationUpdateStatementsLink = exports.ChangePasswordLink = exports.AddBankAccountScreenLink = exports.FinancialBankAccountInformationLink = exports.UploadCacLink = exports.BusinessShareholdersDetailsLink = exports.BusinessShareholdersLink = exports.BusinessOrganizationLink = exports.BusinessAddressLink = exports.BusinessInformationLink = exports.PersonalContactsLink = exports.PersonalAddressLink = exports.PersonalIdentificationLink = exports.PersonalInformationLink = exports.LoanApplicationSummaryLink = exports.LoanApplicationGuarantorLink = exports.NewLoanApplicationScreenLink = exports.PaymentSummaryLink = exports.LoanHistoryLink = exports.LoanActiveLink = exports.LoanHomeLink = exports.HomeLink = exports.ResendEmailConfirmationLink = exports.EmailConfirmedLink = exports.EmailConfirmationLink = exports.EmailConfirmationPendingLink = exports.OnboardingBusinessLink = exports.OnboardingPersonalLink = exports.NowNowInvitationLink = exports.ResetPasswordLink = exports.RecoverPasswordLink = exports.CreateAccountLink = exports.EstimateLoanLink = exports.SignInLink = void 0;
const screenConstants_1 = require("../constants/screenConstants");
// LinkingAuthentication
exports.SignInLink = '/login';
exports.EstimateLoanLink = '/estimate_loan';
exports.CreateAccountLink = '/create_account';
exports.RecoverPasswordLink = '/recover_password';
exports.ResetPasswordLink = '/reset_password';
exports.NowNowInvitationLink = '/profile_invitation';
// LinkingOnboarding
exports.OnboardingPersonalLink = '/onboarding-personal';
exports.OnboardingBusinessLink = '/onboarding-business';
// Email Confirmation pending
exports.EmailConfirmationPendingLink = '/email_confirmation_pending';
exports.EmailConfirmationLink = '/email_confirmation';
exports.EmailConfirmedLink = '/email-confirmed';
// Resend Email
exports.ResendEmailConfirmationLink = '/resend-email';
// LinkingMain
exports.HomeLink = '/home';
exports.LoanHomeLink = '/loan';
exports.LoanActiveLink = '/loans-active';
exports.LoanHistoryLink = '/loans-historic';
exports.PaymentSummaryLink = '/loans-payments-summary';
exports.NewLoanApplicationScreenLink = '/new-loan-application';
exports.LoanApplicationGuarantorLink = '/new-loan-application-guarantor';
exports.LoanApplicationSummaryLink = '/new-loan-application-summary';
exports.PersonalInformationLink = '/profile-personal-information';
exports.PersonalIdentificationLink = '/profile-personal-identification';
exports.PersonalAddressLink = '/profile-personal-address';
exports.PersonalContactsLink = '/profile-personal-contacts';
exports.BusinessInformationLink = '/profile-business-information';
exports.BusinessAddressLink = '/profile-business-address';
exports.BusinessOrganizationLink = '/profile-business-organization';
exports.BusinessShareholdersLink = '/shareholders';
exports.BusinessShareholdersDetailsLink = '/shareholders-details';
exports.UploadCacLink = '/upload-cac';
exports.FinancialBankAccountInformationLink = '/profile-financial-bank-account-information';
exports.AddBankAccountScreenLink = '/connect-bank-account';
exports.ChangePasswordLink = '/profile-personal-change_password';
exports.LoanApplicationUpdateStatementsLink = '/new-loan-application-statements';
exports.UploadBankStatementLink = '/upload-bank-statements';
exports.ProfileLink = '/profile';
exports.PartnerBankAccountInformationLink = '/partner-bank-account-information';
exports.PartnerConnectBankAccountLink = '/partner-connect-bank-account';
exports.PartnerOfferLetterLink = '/partner-offer-letter';
exports.SurveyLink = '/nps';
exports.AcceptDeclineLink = '/accept_decline';
exports.MyBankStatementLink = '/my-bank-statement';
exports.RemitaConnectionLink = '/remita-connection';
// Get screen from path for navigation
exports.screens = {
    [exports.ResetPasswordLink]: screenConstants_1.RESET_PASSWORD_SCREEN,
    [exports.EmailConfirmationPendingLink]: screenConstants_1.EMAIL_CONFIRMATION_PENDING_SCREEN,
    [exports.EmailConfirmationLink]: screenConstants_1.EMAIL_CONFIRMATION_SCREEN,
    [exports.OnboardingPersonalLink]: screenConstants_1.ONBOARDING_PERSONAL_INFORMATION_SCREEN,
    [exports.OnboardingBusinessLink]: screenConstants_1.ONBOARDING_BUSINESS_INFORMATION_SCREEN,
    [exports.HomeLink]: screenConstants_1.HOME_SCREEN,
    [exports.LoanHomeLink]: screenConstants_1.LOAN_HOME_SCREEN,
    [exports.PaymentSummaryLink]: screenConstants_1.PAYMENT_SUMMARY_SCREEN,
    [exports.NewLoanApplicationScreenLink]: screenConstants_1.NEW_LOAN_APPLICATION_SCREEN,
    [exports.LoanApplicationGuarantorLink]: screenConstants_1.LOAN_APPLICATION_GUARANTOR_SCREEN,
    [exports.LoanApplicationSummaryLink]: screenConstants_1.LOAN_APPLICATION_SUMMARY_SCREEN,
    [exports.ProfileLink]: screenConstants_1.PROFILE_SCREEN,
    [exports.PersonalInformationLink]: screenConstants_1.PERSONAL_INFORMATION_SCREEN,
    [exports.PersonalIdentificationLink]: screenConstants_1.PERSONAL_IDENTIFICATION_SCREEN,
    [exports.PersonalAddressLink]: screenConstants_1.PERSONAL_ADDRESS_SCREEN,
    [exports.PersonalContactsLink]: screenConstants_1.PERSONAL_CONTACTS_SCREEN,
    [exports.BusinessInformationLink]: screenConstants_1.BUSINESS_INFORMATION_SCREEN,
    [exports.BusinessAddressLink]: screenConstants_1.BUSINESS_ADDRESS_SCREEN,
    [exports.BusinessOrganizationLink]: screenConstants_1.BUSINESS_ORGANIZATION_SCREEN,
    [exports.FinancialBankAccountInformationLink]: screenConstants_1.FINANCIAL_BANK_INFORMATION_SCREEN,
    [exports.AddBankAccountScreenLink]: screenConstants_1.ADD_BANK_ACCOUNT_SCREEN,
    [exports.ChangePasswordLink]: screenConstants_1.CHANGE_PASSWORD_SCREEN,
    [exports.EmailConfirmedLink]: screenConstants_1.EMAIL_CONFIRMED_SCREEN,
    [exports.ResendEmailConfirmationLink]: screenConstants_1.RESEND_EMAIL_CONFIRMATION_SCREEN,
    [exports.LoanApplicationUpdateStatementsLink]: screenConstants_1.LOAN_APPLICATION_UPDATE_STATEMENTS_SCREEN,
    [exports.UploadBankStatementLink]: screenConstants_1.UPLOAD_BANK_STATEMENTS_SCREEN,
    [exports.PartnerBankAccountInformationLink]: screenConstants_1.PARTNER_BANK_ACCOUNT_INFO,
    [exports.PartnerConnectBankAccountLink]: screenConstants_1.PARTNER_CONNECT_BANK,
    [exports.PartnerOfferLetterLink]: screenConstants_1.PARTNER_OFFER_LETTER,
    [exports.SurveyLink]: screenConstants_1.SURVEY_SCREEN,
    [exports.AcceptDeclineLink]: screenConstants_1.ACCEPT_DECLINE_SCREEN,
    [exports.MyBankStatementLink]: screenConstants_1.MY_BANK_STATEMENT_SCREEN,
    [exports.RemitaConnectionLink]: screenConstants_1.REMITA_CONNECTION_SCREEN,
};
