"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const colors = {
    // COLOR_PRIMARY: '#39C087',
    // COLOR_SECONDARY: '#525D8A',
    // COLOR_WHITE: '#FFFFFF',
    // COLOR_OFF_WHITE: '#F5F5F5',
    // COLOR_BACKGROUND: '#F5F6FA',
    // COLOR_BLACK: '#000000',
    // COLOR_GREEN_DARKEST: '#2C9185',
    // COLOR_GREEN_DARKER: '#2CC185',
    // COLOR_GREEN_DARK: '#00814E',
    // COLOR_GREEN_LIGHT: '#6bb4af',
    // COLOR_GREEN_LIGHTER: '#dbf3e9',
    // COLOR_BLUE: '#525D8A',
    // COLOR_BLUE_LIGHT: '#CACFE7',
    // COLOR_SILVER_DARKER: '#222222',
    // COLOR_SILVER_DARK: '#686868',
    // COLOR_SILVER: '#878787',
    // COLOR_SILVER_LIGHT: '#D1D1D1',
    // COLOR_GRAY_DARK: '#63687D',
    // COLOR_GRAY: '#7B898E',
    // COLOR_GRAY_LIGHT: '#F1F1F1',
    // COLOR_RED_LIGHT: '#D06F6F',
    // COLOR_CRITICAL: '#C83F3F',
    // COLOR_POOR: '#E5A75E',
    COLOR_OK: '#E8D260',
    // COLOR_GOOD: '#B8DE4B',
    // COLOR_EXCELLENT: '#39C087',
    // COLOR_BG: '#FAFAFA',
    // COLOR_BLACK_6: 'rgba(0, 0, 0, 0.6)',
    COLOR_PRIMARY: '#39C087',
    COLOR_SECONDARY: '#525D8A',
    COLOR_WHITE: '#FFFFFF',
    COLOR_OFF_WHITE: '#FBFDFF',
    COLOR_BLACK: '#000000',
    COLOR_GREEN: '#00A568',
    COLOR_GREEN_DARK: '#00814E',
    COLOR_GREEN_LIGHT: '#6bb4af',
    COLOR_GREEN_LIGHTER: '#BFE8D4',
    COLOR_UNDERLINE_GRAY: '#BDBDBD',
    COLOR_GRAY_DARK: '#63687D',
    COLOR_GRAY: '#7B898E',
    COLOR_HOME_GRAY: '#EEF1F0',
    COLOR_GRAY_LIGHT: '#F1F1F1',
    COLOR_GRAY_LIGHTER: '#F2F2F2',
    COLOR_GRAY_LIGHTEST: '#F7F7F7',
    COLOR_CRITICAL: '#EE4266',
    COLOR_BRICK_RED: '#FDE7EC',
    COLOR_BRICK_RED_5: '#E23F61',
    COLOR_RED_VERY_LIGHT: '#FCF5F5',
    COLOR_BLACK_6: 'rgba(0, 0, 0, 0.6)',
    COLOR_BLUE: '#525D8A',
    COLOR_HYPER_BLUE: '#3399FF',
    COLOR_BLUE_LIGHT: '#CACFE7',
    RIPPLE_COLOR: 'rgba(255, 255, 255, .5)',
    // NEW COLORS
    COLOR_GRAY_7: '#E0E0E0',
};
exports.default = colors;
