"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const styles = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        paddingVertical: 8,
    },
    iconMargin: {
        marginRight: 10,
        marginLeft: 5,
    },
    profileText: {
        flex: 4,
        alignItems: 'flex-start',
        alignSelf: 'center',
        paddingLeft: 15,
    },
    subProfileText: {
        flex: 4,
        paddingBottom: 15,
        alignSelf: 'baseline',
        paddingLeft: 25,
        borderBottomWidth: 1,
        borderBottomColor: '#E5E5E5E5',
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
    },
});
exports.default = styles;
