"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.submitBusinessDeleteShareholder = exports.submitBusinessShareholder = exports.deleteCacCertificate = exports.addCacCertificate = exports.getCacCertificates = exports.addRCNumber = exports.submitBusinessOrganization = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const network_1 = __importStar(require("../../../utils/network"));
exports.submitBusinessOrganization = (0, toolkit_1.createAsyncThunk)('profile/businessOrganization', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/business/kyc/business/organization/`, { clientBody: payload, method: 'POST', token: auth.token }, { rejectWithValue });
});
exports.addRCNumber = (0, toolkit_1.createAsyncThunk)('profile/rc/number', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/business/kyc/business/registered-data/add/`, { clientBody: payload, method: 'POST', token: auth.token }, { rejectWithValue });
});
exports.getCacCertificates = (0, toolkit_1.createAsyncThunk)('profile/get/cac/certificates', async (_, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/business/kyc/business/cac-certificates/list/`, { clientBody: {}, method: 'POST', token: auth.token }, { rejectWithValue });
});
exports.addCacCertificate = (0, toolkit_1.createAsyncThunk)('profile/add/cac/certificate', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/business/kyc/business/cac-certificate/add/`, { clientBody: payload, method: 'POST', token: auth.token }, { rejectWithValue }, { 'Accept': 'application/json', 'Content-Type': 'multipart/form-data' });
});
exports.deleteCacCertificate = (0, toolkit_1.createAsyncThunk)('profile/delete/cac/certificate', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/business/kyc/business/cac-certificates/${payload.documentId}/delete/`, { clientBody: {}, method: 'POST', token: auth.token }, { rejectWithValue });
});
// Add shareholder
exports.submitBusinessShareholder = (0, toolkit_1.createAsyncThunk)('profile/addShareholder', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    if (payload.user_id) {
        return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/accounts/business/shareholder/${payload.user_id}/edit/`, { clientBody: payload, method: 'POST', token: auth.token }, { rejectWithValue });
    }
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/accounts/business/shareholder/create/`, { clientBody: payload, method: 'POST', token: auth.token }, { rejectWithValue });
});
// Delete shareholder
exports.submitBusinessDeleteShareholder = (0, toolkit_1.createAsyncThunk)('profile/deleteShareholder', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/accounts/business/shareholder/${payload.shareholderId}/delete/`, { clientBody: {}, method: 'POST', token: auth.token }, { rejectWithValue });
});
