"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAllProviders = exports.getProvider = exports.okraIntegration = exports.deleteStatement = exports.addStatement = exports.submitFinancialMakePrimaryBankAccount = exports.submitFinancialDeleteBankAccount = exports.editBankAccount = exports.submitFinancialAddBankAccount = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const network_1 = __importStar(require("../../../utils/network"));
exports.submitFinancialAddBankAccount = (0, toolkit_1.createAsyncThunk)('profile/addBankAccount', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/business/kyc/financial/bank_account/add/`, { clientBody: payload, method: 'POST', token: auth.token }, { rejectWithValue });
});
// Edit bank details
exports.editBankAccount = (0, toolkit_1.createAsyncThunk)('profile/editBankAccount', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/business/kyc/financial/bank_account/${payload.bank_id}/edit/`, { clientBody: payload, method: 'POST', token: auth.token }, { rejectWithValue });
});
// Delete bank account
exports.submitFinancialDeleteBankAccount = (0, toolkit_1.createAsyncThunk)('profile/deleteBankAccount', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/business/kyc/financial/bank_account/${payload.bankAccountId}/delete/`, { clientBody: payload, method: 'POST', token: auth.token }, { rejectWithValue });
});
// Make primary bank account
exports.submitFinancialMakePrimaryBankAccount = (0, toolkit_1.createAsyncThunk)('profile/makePrimaryBankAccount', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/business/kyc/financial/bank_account/${payload.bankAccountId}/make_primary/`, { clientBody: payload, method: 'POST', token: auth.token }, { rejectWithValue });
});
// Add bank statement to bank account
exports.addStatement = (0, toolkit_1.createAsyncThunk)('profile/add/bank/statement', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/business/kyc/financial/bank_account/${payload.bankAccountId}/bank_statement/add/`, { clientBody: payload.formData, method: 'POST', token: auth.token }, { rejectWithValue }, { 'Accept': 'application/json', 'Content-Type': 'multipart/form-data' });
});
// Delete bank statement
exports.deleteStatement = (0, toolkit_1.createAsyncThunk)('profile/delete/bank/statement', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/business/kyc/financial/bank_statement/${payload.documentId}/delete/`, { clientBody: payload, method: 'POST', token: auth.token }, { rejectWithValue });
});
// Okra
exports.okraIntegration = (0, toolkit_1.createAsyncThunk)('profile/okra/integration', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/okra/submit-customer-id/`, { clientBody: payload, method: 'POST', token: auth.token }, { rejectWithValue });
});
// get provider
exports.getProvider = (0, toolkit_1.createAsyncThunk)('profile/change/provider', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/business/financial/bank_statement/get_provider_after/${payload.provider}/`, { clientBody: payload, method: 'GET', token: auth.token }, { rejectWithValue });
});
exports.getAllProviders = (0, toolkit_1.createAsyncThunk)('profile/get/providers', async (_, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/business/bank_statement/get_all_providers/`, { clientBody: {}, method: 'GET', token: auth.token }, { rejectWithValue });
});
