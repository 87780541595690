"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.progressCardChoices = exports.shareholderRoleChoices = exports.getStatementPlatform = exports.userValidationChoices = exports.leadChoices = exports.educationalChoices = exports.familySizeChoices = exports.yesNoChoicesBoolean = exports.yesNoChoices = exports.genderChoices = exports.titleChoices = exports.maritalChoices = exports.typeDocumentChoices = exports.residentialStatusChoices = exports.yearsInBusinessChoices = exports.positionChoices = exports.averageSalesChoices = exports.industryChoices = exports.businessOperationsChoices = exports.businessTypeChoices = exports.getCacRequirements = exports.LIMITED_COMPANY = exports.PARTNERSHIP = exports.SOLE_PROPRIETORSHIP = exports.officeStatusChoices = exports.lengthChoices = exports.periodicityChoices = exports.loanReasonChoices = void 0;
exports.loanReasonChoices = [
    { value: '', label: '' },
    { value: 1, label: 'Marketing' },
    { value: 2, label: 'Pay a bill' },
    { value: 3, label: 'Pay a salary' },
    { value: 4, label: 'Expand my business' },
    { value: 5, label: 'Purchase inventory' },
    { value: 6, label: 'Purchase equipment' },
    { value: 7, label: 'Refinance a loan' },
];
exports.periodicityChoices = [
    { value: '', label: '' },
    // { value: 1, label: 'Daily', checked: false },
    { value: 7, label: 'Weekly', checked: false },
    // { value: 14, label: 'Fortnightly', checked: false },
    { value: 30, label: 'Monthly', checked: false },
];
exports.lengthChoices = [
    { value: '', label: '' },
    {
        value: 30,
        label: '30 days',
        checked: false,
    },
    {
        value: 60,
        label: '60 days',
        checked: false,
    },
    {
        value: 90,
        label: '90 days',
        checked: false,
    },
    {
        value: 120,
        label: '120 days',
        checked: false,
    },
    {
        value: 150,
        label: '150 days',
        checked: false,
    },
    {
        value: 180,
        label: '180 days',
        checked: false,
    },
];
exports.officeStatusChoices = [
    { value: '', label: '' },
    {
        value: 1,
        label: 'Owned',
        checked: false,
    },
    {
        value: 2,
        label: 'Rented',
        checked: false,
    },
];
exports.SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP';
exports.PARTNERSHIP = 'PARTNERSHIP';
exports.LIMITED_COMPANY = 'LIMITED_COMPANY';
const getCacRequirements = (businessType) => {
    if (businessType === exports.SOLE_PROPRIETORSHIP)
        return ['1) FORM BN 1 (APPLICATION FOR REGISTRATION OF BUSINESS NAME)'];
    if (businessType === exports.PARTNERSHIP)
        return ['1) PARTNERSHIP: FORM BN1 - APPLICATION FOR REGISTRATION OF BUSINESS NAME', '2) BOARD RESOLUTION'];
    if (businessType === exports.LIMITED_COMPANY)
        return [
            '1) CAC FORM 1.1 - APPLICATION FOR REGISTRATION OF BUSINESS',
            '2) CAC FORM 2 - PARTICULARS OF PERSONS WHO ARE FIRST DIRECTORS',
            '3) CAC FORM 7 - STATEMENT OF SHARE CAPITAL',
            '4) BOARD RESOLUTION',
        ];
    return [''];
};
exports.getCacRequirements = getCacRequirements;
exports.businessTypeChoices = [
    {
        value: '',
        label: '',
    },
    {
        value: exports.SOLE_PROPRIETORSHIP,
        label: 'Sole Proprietorship',
    },
    {
        value: exports.PARTNERSHIP,
        label: 'Partnership',
    },
    {
        value: exports.LIMITED_COMPANY,
        label: 'Limited Liability Company(LTD)',
    },
];
exports.businessOperationsChoices = [
    {
        value: '0',
        label: 'Online selling (Jumia, Konga, etc)',
        checked: false,
    },
    {
        value: '1',
        label: 'FMCG goods distributor',
        checked: false,
    },
    {
        value: '2',
        label: 'It has a physical store',
        checked: false,
    },
    {
        value: '3',
        label: 'It has a POS Terminal',
        checked: false,
    },
];
exports.industryChoices = [
    { value: '', label: '' },
    { value: 1, label: 'Agriculture (Farming)' },
    { value: 2, label: 'Oil & Gas' },
    { value: 100, label: 'Autos & Car Parts' },
    { value: 101, label: 'Beauty Products' },
    { value: 102, label: 'Fashion' },
    { value: 103, label: 'Food & Beverages' },
    { value: 104, label: 'Furniture & Fittings' },
    { value: 8, label: 'Health & Pharma Products' },
    { value: 105, label: 'Electronics' },
    { value: 106, label: 'Household Appliances' },
    { value: 4, label: 'Industrial Goods' },
    { value: 107, label: 'Office Supplies' },
    { value: 108, label: 'Packaging & Plastics' },
    { value: 109, label: 'Personal Care' },
    { value: 110, label: 'Phones & Computing' },
    { value: 111, label: 'Consulting Services' },
    { value: 113, label: 'Home Services' },
    { value: 114, label: 'Logistics' },
    { value: 115, label: 'Media and Entertainment' },
    { value: 116, label: 'Professional Services' },
    { value: 117, label: 'Technology Services' },
    { value: 118, label: 'Utility Services' },
    { value: 119, label: 'Agency Banking (POS)' },
    { value: 127, label: 'Transportation' },
    { value: 128, label: 'Telecommunications' },
    { value: 129, label: 'Supermarket' },
    { value: 130, label: 'Retail Trade' },
    { value: 131, label: 'Ecommerce' },
    { value: 132, label: 'Agro-chemicals' },
    { value: 122, label: 'Forex' },
    { value: 123, label: 'Betting' },
    { value: 124, label: 'Cryptocurrency' },
    { value: 125, label: 'Ride Hailing' },
    { value: 126, label: 'Haulage' },
    { value: 112, label: 'Education (School)' },
];
exports.averageSalesChoices = [
    { value: '', label: '' },
    { value: 1, label: '0-1 M' },
    { value: 2, label: '1-5 M' },
    { value: 3, label: '6-10 M' },
    { value: 4, label: '11-20 M' },
    { value: 5, label: '21-40 M' },
    { value: 6, label: '>40 M' },
];
exports.positionChoices = [
    {
        value: '',
        label: '',
    },
    {
        value: 1,
        label: 'Director & Major Shareholder (>50% ownership)',
    },
    {
        value: 2,
        label: 'Director',
    },
    {
        value: 3,
        label: 'Major Shareholder (>50% ownership)',
    },
    {
        value: 4,
        label: 'Other',
    },
];
exports.yearsInBusinessChoices = [
    {
        value: '',
        label: '',
    },
    {
        value: 1,
        label: 'Less than 1 year',
    },
    {
        value: 2,
        label: '1 to 3 years',
    },
    {
        value: 3,
        label: '3 to 5 years',
    },
    {
        value: 4,
        label: '5 to 10 years',
    },
    {
        value: 5,
        label: 'More than 10 years',
    },
];
exports.residentialStatusChoices = [
    {
        value: 0,
        label: '',
    },
    {
        value: 1,
        label: 'I am the owner',
    },
    {
        value: 2,
        label: 'It is rented',
    },
    {
        value: 3,
        label: 'Temporary home',
    },
];
exports.typeDocumentChoices = [
    {
        value: '',
        label: '',
    },
    {
        value: 1,
        label: 'National ID card',
    },
    {
        value: 2,
        label: 'Nigerian passport',
    },
    {
        value: 3,
        label: 'Drivers licence',
    },
    {
        value: 4,
        label: 'Permanent voters card',
    },
];
exports.maritalChoices = [
    {
        value: '',
        label: '',
    },
    {
        value: 1,
        label: 'Married',
    },
    {
        value: 2,
        label: 'Single',
    },
    {
        value: 3,
        label: 'Divorced',
    },
    {
        value: 4,
        label: 'Widowed',
    },
];
exports.titleChoices = [
    {
        value: '',
        label: '',
    },
    {
        value: 1,
        label: 'Mr',
    },
    {
        value: 2,
        label: 'Mrs',
    },
    {
        value: 3,
        label: 'Miss',
    },
];
exports.genderChoices = [
    { value: '', label: '' },
    { value: 1, label: 'Male' },
    { value: 2, label: 'Female' },
];
exports.yesNoChoices = [
    {
        value: '',
        label: '',
    },
    {
        value: 1,
        label: 'Yes',
    },
    {
        value: 0,
        label: 'No',
    },
];
exports.yesNoChoicesBoolean = [
    {
        value: '',
        label: '',
    },
    {
        value: true,
        label: 'Yes',
    },
    {
        value: false,
        label: 'No',
    },
];
exports.familySizeChoices = [
    { value: '', label: '' },
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
    { value: 11, label: '10+' },
];
exports.educationalChoices = [
    { value: '', label: '' },
    { value: 1, label: 'Primary School' },
    { value: 2, label: "Secondary (O'Level)" },
    { value: 3, label: 'Ordinary National Diploma (OND)' },
    { value: 4, label: 'Higher National Diploma (HND)' },
    { value: 5, label: 'Bachelor Degree' },
    { value: 8, label: 'PGD' },
    { value: 6, label: 'Master' },
    { value: 7, label: 'PHD' },
];
exports.leadChoices = [
    { value: '', label: '' },
    { value: 1, label: 'A Lendigo staff' },
    { value: 2, label: 'A Colleague/Friend' },
    { value: 3, label: 'Facebook' },
    { value: 4, label: 'Instagram' },
    { value: 5, label: 'Twitter' },
    { value: 6, label: 'Google' },
    { value: 7, label: 'Other' },
];
exports.userValidationChoices = {
    choice_nin: {
        value: 0,
        label: 'NIN',
    },
    choice_bvn: {
        value: 1,
        label: 'BVN',
    },
};
exports.getStatementPlatform = [
    { value: 'Okra', label: 'Okra' },
    { value: 'Mono', label: 'Mono' },
];
exports.shareholderRoleChoices = [
    {
        value: 'undefined',
        label: '',
    },
    {
        value: 'DIRECTOR',
        label: 'DIRECTOR',
    },
    {
        value: 'PARTNER',
        label: 'PARTNER',
    },
];
exports.progressCardChoices = [
    {
        value: 0,
        label: 'Information goes here',
        image: 'faUser',
        eventName: 'profile_progress_card',
    },
    {
        value: 1,
        label: 'Your Business Information',
        image: 'faBuilding',
        eventName: 'business_progress_card',
    },
    {
        value: 2,
        label: 'Financial details',
        image: 'faChartLine',
        eventName: 'financial_progress_card',
    },
];
