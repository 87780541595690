"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatementsContext = exports.getProviderAux = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_1 = __importStar(require("react"));
const financialBankAccountSlice_1 = require("../features/profile/financialBankAccount.tsx/financialBankAccountSlice");
const getProviderAux = async (dispatch, providerData, logEventWithExtraProperties) => {
    if (providerData) {
        const result = await dispatch((0, financialBankAccountSlice_1.getProvider)({ provider: providerData.currentProvider }));
        logEventWithExtraProperties('api_call', {
            name: 'get_next_statement_provider',
            status: 'submitted',
            location: 'screen_getProviderAux',
            current: providerData.currentProvider,
        });
        if ((0, toolkit_1.isFulfilled)(result)) {
            logEventWithExtraProperties('api_call', {
                name: 'get_next_statement_provider',
                status: 'success',
                location: 'screen_getProviderAux',
                current: providerData.currentProvider,
            });
            providerData.setCurrentProvider(result.payload);
        }
        else {
            logEventWithExtraProperties('api_call', {
                name: 'get_next_statement_provider',
                status: 'fail',
                location: 'screen_getProviderAux',
                current: providerData.currentProvider,
            });
        }
    }
};
exports.getProviderAux = getProviderAux;
exports.StatementsContext = (0, react_1.createContext)({
    getProviderAux: exports.getProviderAux,
    toggleOkraShowing: () => { },
});
const StatementsProvider = ({ children }) => {
    // const onSuccessOkraHelper = async (data: OkraDataType) => {
    //     const result = await dispatch(okraIntegration(getPayload(data)));
    //     if (isFulfilled(result)) {
    //         dispatch(
    //             addMessages([
    //                 {
    //                     title: '',
    //                     message: APP_MESSAGES.BANK_CONNECTED_SUCCESS,
    //                     iconType: GREEN_CHECK_CIRCLE,
    //                     iconColor: theme.colors.COLOR_PRIMARY,
    //                     iconSize: 55,
    //                     buttonText: 'DONE',
    //                     actionButton: () => {
    //                         // get next message in missing loan application data
    //                         dispatch(showMessage());
    //                     },
    //                 },
    //             ]),
    //         );
    //         dispatch(showMessage());
    //     } else {
    //         await dispatch(getProvider({provider: BANK_STATEMENT_PROVIDER_OKRA}));
    //         const screenMessage = APP_MESSAGES.LAST_PROVIDER_FAIL_CONNECT.replace('<<lastProviderName>>', 'OKRA');
    //         dispatch(
    //             addMessages([
    //                 {
    //                     title: 'Oops',
    //                     message: screenMessage,
    //                     iconType: ALERT_ICON,
    //                     iconColor: theme.colors.COLOR_CRITICAL,
    //                     iconSize: 55,
    //                     buttonText: 'TRY AGAIN',
    //                 },
    //             ]),
    //         );
    //         dispatch(showMessage());
    //     }
    // };
    // const onErrorOkraHelper = async () => {
    //     logEvent('widget_okra_error');
    //     const result = await dispatch(getProvider({provider: BANK_STATEMENT_PROVIDER_OKRA}));
    //     if (isFulfilled(result)) {
    //         const screenMessage = APP_MESSAGES.LAST_PROVIDER_FAIL_CONNECT.replace('<<lastProviderName>>', 'OKRA');
    //         dispatch(
    //             addMessages([
    //                 {
    //                     title: 'Oops',
    //                     message: screenMessage,
    //                     iconType: 'faExclamationTriangle',
    //                     iconColor: theme.colors.COLOR_CRITICAL,
    //                     iconSize: 55,
    //                     buttonText: 'TRY AGAIN',
    //                 },
    //             ]),
    //         );
    //         dispatch(showMessage());
    //     }
    // };
    const content = (react_1.default.createElement(exports.StatementsContext.Provider, { value: {
            getProviderAux: exports.getProviderAux,
        } }, children));
    return (react_1.default.createElement(react_1.default.Fragment, null, content));
};
exports.default = StatementsProvider;
