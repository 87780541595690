"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const constants_1 = require("../../constants/constants");
const textStyles = react_native_1.StyleSheet.create({
    font: {
        fontFamily: constants_1.FONT_FAMILY,
        letterSpacing: 0.25,
        lineHeight: 20,
    },
    subtitleText: {
        fontSize: 14,
    },
    biggerSubtitleText: {
        fontSize: 16,
    },
    smallerSubtitleText: {
        fontSize: 12,
    },
    smallestSubtitleText: {
        fontSize: 10,
    },
    bold: {
        fontWeight: 'bold',
    },
    noBold: {
        fontWeight: 'normal',
    },
    centerText: {
        textAlign: 'center',
    },
    underline: {
        textDecorationLine: 'underline',
    },
    errorLabel: {
        fontSize: 12,
        fontWeight: '400',
        fontStyle: 'normal',
        lineHeight: 16,
    },
    titleText: {
        fontSize: 24,
        lineHeight: 36,
    },
    smallerTitleText: {
        fontSize: 18,
    },
    graySubtitleText: {
        lineHeight: 20,
        fontWeight: '400',
    },
});
exports.default = textStyles;
