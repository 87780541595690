"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const styles = react_native_1.StyleSheet.create({
    container: {
        flexDirection: 'row',
        marginVertical: 10,
        paddingVertical: 10,
    },
    columnDirection: {
        flexDirection: 'column',
    },
    flexThreeStart: {
        flex: 1,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
});
exports.default = styles;
