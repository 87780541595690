"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PARTNER_BANK_ACCOUNT_INFO = exports.PARTNER_OFFER_LETTER = exports.PARTNER_CONNECT_BANK = exports.REMITA_CONNECTION_SCREEN = exports.ACCEPT_DECLINE_SCREEN = exports.SURVEY_SCREEN = exports.WEBVIEW_SCREEN = exports.NOWNOW_INVITATION_SCREEN = exports.MY_BANK_STATEMENT_SCREEN = exports.UPLOAD_BANK_STATEMENTS_SCREEN = exports.LOAN_APPLICATION_SUMMARY_SCREEN = exports.LOAN_APPLICATION_UPDATE_STATEMENTS_SCREEN = exports.LOAN_APPLICATION_GUARANTOR_SCREEN = exports.NEW_LOAN_APPLICATION_SCREEN = exports.ADD_BANK_ACCOUNT_SCREEN = exports.FINANCIAL_BANK_INFORMATION_SCREEN = exports.UPLOAD_CAC_SCREEN = exports.BUSINESS_SHAREHOLDERS_SCREEN = exports.BUSINESS_ORGANIZATION_SCREEN = exports.BUSINESS_INFORMATION_SCREEN = exports.BUSINESS_ADDRESS_SCREEN = exports.PERSONAL_CONTACTS_SCREEN = exports.PERSONAL_ADDRESS_SCREEN = exports.PERSONAL_IDENTIFICATION_SCREEN = exports.PERSONAL_INFORMATION_SCREEN = exports.CHANGE_PASSWORD_SCREEN = exports.PROFILE_SCREEN = exports.PAYMENT_SUMMARY_SCREEN = exports.LOAN_HOME_SCREEN = exports.HOME_SCREEN = exports.ONBOARDING_BUSINESS_INFORMATION_SCREEN = exports.ONBOARDING_PERSONAL_INFORMATION_SCREEN = exports.RESEND_EMAIL_CONFIRMATION_SCREEN = exports.EMAIL_CONFIRMED_SCREEN = exports.EMAIL_CONFIRMATION_SCREEN = exports.EMAIL_CONFIRMATION_PENDING_SCREEN = exports.RESET_PASSWORD_SCREEN = exports.RECOVER_PASSWORD_SCREEN = exports.ESTIMATE_LOAN_SCREEN = exports.SIGN_IN_SCREEN = exports.CREATE_ACCOUNT_SCREEN = void 0;
// AUTH SCREENS
exports.CREATE_ACCOUNT_SCREEN = 'CreateAccount';
exports.SIGN_IN_SCREEN = 'SignIn';
exports.ESTIMATE_LOAN_SCREEN = 'EstimateLoan';
exports.RECOVER_PASSWORD_SCREEN = 'RecoverPassword';
exports.RESET_PASSWORD_SCREEN = 'ResetPassword';
exports.EMAIL_CONFIRMATION_PENDING_SCREEN = 'EmailConfirmationPending';
exports.EMAIL_CONFIRMATION_SCREEN = 'EmailConfirmation';
exports.EMAIL_CONFIRMED_SCREEN = 'EmailConfirmed';
exports.RESEND_EMAIL_CONFIRMATION_SCREEN = 'ResendEmailConfirmation';
// ONBOARDING SCREENS
exports.ONBOARDING_PERSONAL_INFORMATION_SCREEN = 'OnboardingPersonalInformation';
exports.ONBOARDING_BUSINESS_INFORMATION_SCREEN = 'OnboardingBusinessInformation';
// HOME SCREEN
exports.HOME_SCREEN = 'Home';
// LOAN SCREEN
exports.LOAN_HOME_SCREEN = 'LoanHome';
exports.PAYMENT_SUMMARY_SCREEN = 'PaymentSummary';
// PROFILE SCREEN
exports.PROFILE_SCREEN = 'Profile';
exports.CHANGE_PASSWORD_SCREEN = 'ChangePassword';
// PERSONAL SCREENS
exports.PERSONAL_INFORMATION_SCREEN = 'PersonalInformation';
exports.PERSONAL_IDENTIFICATION_SCREEN = 'PersonalIdentification';
exports.PERSONAL_ADDRESS_SCREEN = 'PersonalAddress';
exports.PERSONAL_CONTACTS_SCREEN = 'PersonalContacts';
// BUSINESS SCREENS
exports.BUSINESS_ADDRESS_SCREEN = 'BusinessAddress';
exports.BUSINESS_INFORMATION_SCREEN = 'BusinessInformation';
exports.BUSINESS_ORGANIZATION_SCREEN = 'BusinessOrganization';
exports.BUSINESS_SHAREHOLDERS_SCREEN = 'BusinessShareholders';
exports.UPLOAD_CAC_SCREEN = 'UploadCac';
// FINANCIAL SCREENS
exports.FINANCIAL_BANK_INFORMATION_SCREEN = 'FinancialBankAccountInformation';
exports.ADD_BANK_ACCOUNT_SCREEN = 'ConnectBankAccount';
// LOAN APPLICATION SCREEN
exports.NEW_LOAN_APPLICATION_SCREEN = 'NewLoanApplicationScreen';
exports.LOAN_APPLICATION_GUARANTOR_SCREEN = 'LoanApplicationGuarantor';
exports.LOAN_APPLICATION_UPDATE_STATEMENTS_SCREEN = 'LoanApplicationUpdateStatements';
exports.LOAN_APPLICATION_SUMMARY_SCREEN = 'LoanApplicationSummary';
// BANK STATEMENTS SCREEN
exports.UPLOAD_BANK_STATEMENTS_SCREEN = 'UploadBankStatement';
// MBS SCREEN
exports.MY_BANK_STATEMENT_SCREEN = 'MyBankStatementScreen';
// OTHER SCREENS
exports.NOWNOW_INVITATION_SCREEN = 'NowNowInvitation';
exports.WEBVIEW_SCREEN = 'Webview';
exports.SURVEY_SCREEN = 'Survey';
exports.ACCEPT_DECLINE_SCREEN = 'AcceptDeclineBase';
// JUMIA SCREENS
// export const JUMIA_OFFER_LETTER = 'JumiaOfferLetter';
// export const JUMIA_CONNECT_BANK = 'JumiaConnectBankAccount';
// export const JUMIA_BANK_ACCOUNT_INFO = 'JumiaBankAccountInformation';
// REMITA SCREENS
exports.REMITA_CONNECTION_SCREEN = 'RemitaConnection';
// PARTNERS SCREENS
exports.PARTNER_CONNECT_BANK = 'PartnerConnectBank';
exports.PARTNER_OFFER_LETTER = 'PartnerOfferLetter';
exports.PARTNER_BANK_ACCOUNT_INFO = 'PartnerBankAccountInformation';
