"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const generalStyles = react_native_1.StyleSheet.create({
    flexDirectionRow: {
        flexDirection: 'row',
    },
    flexDirectionColumn: {
        flexDirection: 'column',
    },
    justifyFlexEnd: {
        justifyContent: 'flex-end',
    },
    justifyFlexStart: {
        justifyContent: 'flex-start',
    },
    justifySpaceBetween: {
        justifyContent: 'space-between',
    },
    flexOne: {
        flex: 1,
    },
    flexTwo: {
        flex: 2,
    },
    flexThree: {
        flex: 3,
    },
    flexGrowOne: {
        flexGrow: 1,
    },
    centerChildHorizontalVertical: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    mainContainerPadding: {
        paddingHorizontal: 25,
    },
    minimumMarginBetweenElementsVertically: {
        marginVertical: 10,
    },
    noDisplay: {
        display: 'none',
    },
});
exports.default = generalStyles;
