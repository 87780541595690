"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.authInterceptor = void 0;
// import {MiddlewareAPI} from 'redux';
const authSlice_1 = require("../features/auth/authSlice");
// import {logEventUtil} from './analytics/analytics';
const network_1 = require("./network");
/**
 * Auxiliary function of the actions we want to log.
 */
// const shouldLogActionType = (actionType: string) => {
//     // Ignore all persist actions
//     if (actionType.startsWith('persist/')) {
//         return false;
//     }
//     return true;
// };
/**
 * Check if token is valid.
 */
// eslint-disable-next-line
const authInterceptor = () => (next) => (action) => {
    if (action.payload === network_1.REJECT_VALUE_401) {
        next((0, authSlice_1.invalidSessionToken)());
    }
    return next(action);
};
exports.authInterceptor = authInterceptor;
/**
 * Logs all actions and states after they are dispatched.
 */
// TODO: This uses code that is not in analytics context, doesn't look right to me at least now,
// check in future Dominik
// export const logger =
//     // eslint-disable-next-line
//     (store: MiddlewareAPI) => (next: Dispatch<PayloadAction<any>>) => (action: PayloadAction<any>) => {
//         if (shouldLogActionType(action.type)) {
//             // next(setIsLoading({isLoading: true}));
//             const result = next(action);
//             const eventName = action.type.replace(/\//g, '_').substring(0, 39); // replaces all / with _
//             if (isFulfilled(result) || isRejectedWithValue(result)) {
//                 const state = store.getState();
//                 logEventUtil(`api_${eventName}`.toLowerCase(), state.auth.email, state.auth.id);
//             }
//             // next(setIsLoading({isLoading: false}));
//             return result;
//         }
//         return next(action);
//     };
