"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendUserLocation = exports.sendUserContacts = exports.sendDeviceApplications = exports.sendSystemData = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const network_1 = __importStar(require("../../utils/network"));
exports.sendSystemData = (0, toolkit_1.createAsyncThunk)('device/system/info', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/device/system/info/`, { clientBody: payload, method: 'POST', token: auth.token }, { rejectWithValue });
});
exports.sendDeviceApplications = (0, toolkit_1.createAsyncThunk)('device/system/apps', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/device/applications/`, { clientBody: payload, method: 'POST', token: auth.token }, { rejectWithValue });
});
exports.sendUserContacts = (0, toolkit_1.createAsyncThunk)('device/user/contacts', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/device/contacts/`, { clientBody: payload, method: 'POST', token: auth.token }, { rejectWithValue });
});
exports.sendUserLocation = (0, toolkit_1.createAsyncThunk)('device/user/location', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/device/${payload.userId}/userinfo/save/?d=location/`, { clientBody: { location: payload.location }, method: 'POST', token: auth.token }, { rejectWithValue });
});
