"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.savePartnerName = exports.invalidSessionToken = exports.cleanDeepLink = exports.autoLogin = exports.setToken = exports.resetNowNowStore = exports.setLoginOrRegister = exports.setMessageOudatedApp = exports.setMessageDisabledApp = exports.setInitialState = exports.resetAuthSlice = exports.logout = exports.setOnboardingWatched = exports.setIsLoading = exports.updateAdjust = exports.userConfirmEmail = exports.requestConfirmEmail = exports.createNowNowPassword = exports.getNowNowPassword = exports.submitResetPassword = exports.recoverPassword = exports.signUp = exports.temporaryAccount = exports.login = exports.validateToken = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const typesConstants_1 = require("../../constants/typesConstants");
const network_1 = __importStar(require("../../utils/network"));
const VALID_STATUS = 'valid';
const INVALID_STATUS = 'invalid';
const initialState = {
    token: '',
    isLoading: true,
    token_status: typesConstants_1.IDLE_STATUS,
    message: '',
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    banking: {
        collection_account: {
            bank_name: '',
            bank_account_number: '',
        },
    },
    app_version_status: '',
    loginOrRegister: true,
    loan_estimate: '100000',
    // Now Now
    valid_code: false,
    // Deep Link
    deepLinkData: {},
};
exports.validateToken = (0, toolkit_1.createAsyncThunk)('auth/validateToken', async (_, { getState, rejectWithValue }) => {
    const { auth } = getState();
    (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/accounts/validate-token/`, { clientBody: {}, method: 'GET', token: auth.token }, { rejectWithValue });
});
exports.login = (0, toolkit_1.createAsyncThunk)('auth/login', async (payload, { rejectWithValue }) => (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/accounts/login/`, { clientBody: payload, method: 'POST' }, { rejectWithValue }));
exports.temporaryAccount = (0, toolkit_1.createAsyncThunk)('auth/temporaryAccount', async (payload, { rejectWithValue }) => (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/accounts/temporary/`, { clientBody: payload, method: 'POST' }, { rejectWithValue }));
exports.signUp = (0, toolkit_1.createAsyncThunk)('auth/signUp', async (payload, { rejectWithValue }) => (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/accounts/register/`, { clientBody: payload, method: 'POST' }, { rejectWithValue }));
exports.recoverPassword = (0, toolkit_1.createAsyncThunk)('auth/recoverPassword', async (payload, { rejectWithValue }) => (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/accounts/reset-password/`, { clientBody: payload, method: 'POST' }, { rejectWithValue }));
exports.submitResetPassword = (0, toolkit_1.createAsyncThunk)('auth/submitResetPassword', async (payload, { rejectWithValue }) => (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/accounts/api-reset-password/`, { clientBody: payload, method: 'POST' }, { rejectWithValue }));
exports.getNowNowPassword = (0, toolkit_1.createAsyncThunk)('auth/getNowNowPassword', async (payload, { rejectWithValue }) => (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/accounts/profile-invitation/${payload.invitationKey}`, { clientBody: {}, method: 'GET' }, { rejectWithValue }));
exports.createNowNowPassword = (0, toolkit_1.createAsyncThunk)('auth/createNowNowPassword', async (payload, { rejectWithValue }) => {
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/accounts/profile-invitation/${payload.invitationKey}`, {
        clientBody: {
            password_1: payload.password_1,
            password_2: payload.password_2,
        },
        method: 'POST',
    }, { rejectWithValue });
});
// Request Confirm email
exports.requestConfirmEmail = (0, toolkit_1.createAsyncThunk)('auth/requestConfirmEmail', async (_, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/accounts/email-verification/request/`, { clientBody: {}, method: 'GET', token: auth.token }, { rejectWithValue });
});
// User confirm email
exports.userConfirmEmail = (0, toolkit_1.createAsyncThunk)('auth/userConfirmEmail', async (payload, { rejectWithValue }) => {
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/accounts/email-verification/${payload.token}/verify/`, { clientBody: payload, method: 'POST' }, { rejectWithValue });
});
exports.updateAdjust = (0, toolkit_1.createAsyncThunk)('auth/updateAdjustData', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/accounts/update-adjust-data/`, { clientBody: payload, method: 'POST', token: auth.token }, { rejectWithValue });
});
const authSlice = (0, toolkit_1.createSlice)({
    name: 'auth',
    initialState,
    reducers: {
        setIsLoading(state, { payload }) {
            return { ...state, ...payload };
        },
        setOnboardingWatched(state) {
            const newState = { ...state };
            return newState;
        },
        logout(state) {
            const newState = { ...state };
            newState.token = '';
            newState.message = '';
            return newState;
        },
        savePartnerName(state, { payload }) {
            const newState = { ...state };
            newState.partner_name = payload;
            return newState;
        },
        invalidSessionToken(state) {
            const newState = { ...state };
            newState.token = '';
            newState.message = 'Your session has expired. Please sign in again.';
            return newState;
        },
        resetAuthSlice(state) {
            const newState = { ...initialState };
            newState.token = '';
            newState.message = state.message;
            newState.email = state.email;
            newState.id = state.id;
            return newState;
        },
        setInitialState(state) {
            const newState = { ...initialState };
            newState.token = state.token;
            return newState;
        },
        setMessageDisabledApp(state) {
            const newState = { ...state };
            newState.message = 'Your version of the app has been disabled. Please update in the store.';
            return newState;
        },
        setMessageOudatedApp(state) {
            const newState = { ...state };
            newState.message = 'Please update your app in the store.';
            return newState;
        },
        setLoginOrRegister(state) {
            const newState = { ...state };
            newState.loginOrRegister = !state.loginOrRegister;
            return newState;
        },
        resetNowNowStore(state) {
            const newState = { ...state };
            newState.valid_code = false;
            newState.error = undefined;
            return newState;
        },
        setToken(state, { payload }) {
            const newState = { ...state, token: payload.token };
            return newState;
        },
        autoLogin(state, action) {
            const newState = { ...state, ...action.payload };
            return newState;
        },
        cleanDeepLink(state) {
            const newState = { ...state };
            newState.deepLinkData = {};
            newState.token_status = typesConstants_1.IDLE_STATUS;
            return newState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(exports.validateToken.fulfilled, (state) => ({
            ...state,
            token_status: VALID_STATUS,
        }))
            .addCase(exports.login.fulfilled, (state, { payload }) => ({
            ...state,
            message: '',
            id: payload.id,
            email: payload.email,
            token: payload.token,
            app_version_status: payload.app_version_status,
        }))
            .addCase(exports.signUp.fulfilled, (state, { payload }) => ({
            ...state,
            message: '',
            id: payload.id,
            email: payload.email,
            token: payload.token,
            app_version_status: payload.app_version_status,
        }))
            .addCase(exports.temporaryAccount.fulfilled, (state, { payload }) => ({
            ...state,
            email: payload.email,
            loan_estimate: payload.loan_estimate,
        }))
            // Now Now
            .addCase(exports.createNowNowPassword.fulfilled, (state, { payload }) => {
            if ('valid_code' in payload) {
                return { ...state, valid_code: payload.valid_code };
            }
            return {
                ...state,
                id: payload.id,
                email: payload.email,
                token: payload.token,
                app_version_status: payload.app_version_status,
            };
        })
            // Email confirmation
            .addCase(exports.requestConfirmEmail.fulfilled, (state, { payload }) => ({
            ...state,
            message: payload.message,
        }))
            // User email confirmation
            .addCase(exports.userConfirmEmail.fulfilled, (state, { payload }) => ({
            ...state,
            message: payload.message,
        }));
    },
});
_a = authSlice.actions, exports.setIsLoading = _a.setIsLoading, exports.setOnboardingWatched = _a.setOnboardingWatched, exports.logout = _a.logout, exports.resetAuthSlice = _a.resetAuthSlice, exports.setInitialState = _a.setInitialState, exports.setMessageDisabledApp = _a.setMessageDisabledApp, exports.setMessageOudatedApp = _a.setMessageOudatedApp, exports.setLoginOrRegister = _a.setLoginOrRegister, exports.resetNowNowStore = _a.resetNowNowStore, exports.setToken = _a.setToken, exports.autoLogin = _a.autoLogin, exports.cleanDeepLink = _a.cleanDeepLink, exports.invalidSessionToken = _a.invalidSessionToken, exports.savePartnerName = _a.savePartnerName;
exports.default = authSlice.reducer;
