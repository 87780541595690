"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const styles = react_native_1.StyleSheet.create({
    container: {
        flexDirection: 'row',
        flex: 1,
    },
    justifyCenter: {
        justifyContent: 'center',
    },
    info: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    webVerticalFix: {
        paddingTop: 0,
        marginTop: 0,
    },
    titleMarginLeft: {
        marginLeft: 5,
    },
});
exports.default = styles;
