"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.populateDeepLinkParams = exports.getDeepLinkData = exports.deepLinkSchema = void 0;
const linkingConstants_1 = require("../appNavigation/linkingConstants");
const homeSlice_1 = require("../features/home/homeSlice");
const loanApplicationSlice_1 = require("../features/loanApplication/loanApplicationSlice");
exports.deepLinkSchema = 'https://app.lendigo.ng';
// export const deepLinkSchema = 'http://localhost:8080';
// Get data from deeplink
const getDeepLinkData = (deepLink) => {
    // eslint-disable-next-line
    const deepData = {};
    // Empty deeplink - no params
    if (!deepLink)
        return deepData;
    // Wrong deepLink schema
    if (!deepLink.includes(exports.deepLinkSchema)) {
        return deepData;
    }
    const splitLink = deepLink.split('?');
    // Get deepLink screen
    if (splitLink[0]) {
        const screen = splitLink[0].replace(exports.deepLinkSchema, '');
        deepData.screen = screen;
    }
    // Deeplink has no params
    if (!splitLink[1])
        return deepData;
    const params = new URLSearchParams(splitLink[1]);
    // Something wrong with params
    if (!params)
        return deepData;
    // Iterate the search parameters.
    params.forEach((value, key) => {
        deepData[key] = value;
    });
    return deepData;
};
exports.getDeepLinkData = getDeepLinkData;
// Pass params from deep link to reducer
// eslint-disable-next-line
const populateDeepLinkParams = (deepLinkData, dispatch) => {
    if (!deepLinkData.screen && !deepLinkData.token)
        return;
    const { screen } = deepLinkData;
    // Set params from deepLink
    if (screen === linkingConstants_1.LoanApplicationSummaryLink) {
        dispatch((0, loanApplicationSlice_1.loanApplicationCreateSliceParamsFromDeepLink)(deepLinkData));
    }
    // Jumia offer letter
    if (screen === linkingConstants_1.PartnerOfferLetterLink) {
        dispatch((0, loanApplicationSlice_1.loanApplicationSliceParamsFromDeepLink)(deepLinkData));
    }
    // nps survey
    if (screen === linkingConstants_1.SurveyLink || screen === linkingConstants_1.AcceptDeclineLink) {
        dispatch((0, homeSlice_1.surveySliceParamsFromDeepLink)(deepLinkData));
    }
};
exports.populateDeepLinkParams = populateDeepLinkParams;
