"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GUARANTOR_CREATED = exports.UPLOADED_STATUS = exports.SUCCEEDED_STATUS = exports.FAILED_STATUS = exports.PENDING_STATUS = exports.IDLE_STATUS = void 0;
// STATUS TYPES
exports.IDLE_STATUS = 'idle';
exports.PENDING_STATUS = 'pending';
exports.FAILED_STATUS = 'failed';
exports.SUCCEEDED_STATUS = 'succeeded';
exports.UPLOADED_STATUS = 'uploaded';
exports.GUARANTOR_CREATED = 'guarantor_created';
