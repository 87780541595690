"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.showMessage = exports.addMessages = exports.hideMessage = exports.surveySliceParamsFromDeepLink = exports.resetHomeState = exports.setMessage = exports.submitUserRating = exports.getUpdates = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const network_1 = __importStar(require("../../utils/network"));
const initialState = {
    kyc: {
        business: false,
        personal: false,
    },
    nps_survey: null,
    warnings: null,
    user_data: null,
    business_data: null,
    financial_data: null,
    collection_account: null,
    loan_history: [],
};
exports.getUpdates = (0, toolkit_1.createAsyncThunk)('home/updates', async (_, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/dashboard/index/`, { clientBody: {}, method: 'GET', token: auth.token }, { rejectWithValue });
});
exports.submitUserRating = (0, toolkit_1.createAsyncThunk)('home/rating', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/accounts/user/rating/`, { clientBody: payload, method: 'POST', token: auth.token }, { rejectWithValue });
});
const homeSlice = (0, toolkit_1.createSlice)({
    name: 'home',
    initialState,
    reducers: {
        resetHomeState() {
            return initialState;
        },
        setMessage(state, { payload }) {
            return { ...state, ...payload };
        },
        hideMessage(state) {
            let newAppMessages = state.appMessages;
            if (newAppMessages) {
                if (newAppMessages.messages.length >= 1) {
                    newAppMessages = {
                        messages: newAppMessages.messages.filter((_, index) => index >= 1),
                        display: false,
                    };
                }
                else {
                    newAppMessages = undefined;
                }
            }
            return { ...state, appMessages: newAppMessages };
        },
        addMessages(state, { payload }) {
            if (state.appMessages && state.appMessages.messages.length > 0) {
                return { ...state, appMessages: { messages: [...payload, ...state.appMessages.messages], display: false } };
            }
            return { ...state, appMessages: { messages: [...payload], display: false } };
        },
        showMessage(state) {
            if (state.appMessages) {
                return { ...state, appMessages: { messages: [...state.appMessages.messages], display: true } };
            }
            return { ...state };
        },
        // Deep link populate reducer with params
        surveySliceParamsFromDeepLink(state, action) {
            const newState = { ...state };
            if (newState.nps_survey) {
                return newState;
            }
            newState.nps_survey = { ...action.payload };
            return newState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(exports.getUpdates.fulfilled, (state, { payload }) => ({
            ...state,
            ...payload,
        }))
            .addCase(exports.submitUserRating.fulfilled, (state, { payload }) => ({
            ...state,
            user_data: payload.user_data,
        }));
    },
});
_a = homeSlice.actions, exports.setMessage = _a.setMessage, exports.resetHomeState = _a.resetHomeState, exports.surveySliceParamsFromDeepLink = _a.surveySliceParamsFromDeepLink, exports.hideMessage = _a.hideMessage, exports.addMessages = _a.addMessages, exports.showMessage = _a.showMessage;
exports.default = homeSlice.reducer;
