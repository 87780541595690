"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const screenConstants_1 = require("../../constants/screenConstants");
const openExternalLink = ({ webViewData, url, navigation }) => {
    const { sourcePage, sourceScreen, name, title } = webViewData;
    if (react_native_1.Platform.OS === 'web') {
        react_native_1.Linking.canOpenURL(url).then(() => {
            return react_native_1.Linking.openURL(url);
        });
    }
    else {
        navigation.navigate(screenConstants_1.WEBVIEW_SCREEN, {
            sourcePage,
            sourceScreen,
            name,
            title,
            url,
        });
    }
};
exports.default = openExternalLink;
