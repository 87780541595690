"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.loanApplicationCreateSliceParamsFromDeepLink = exports.loanApplicationSliceParamsFromDeepLink = exports.setLoanApplicationTerms = exports.setInitialState = exports.editLoanApplicationSummary = exports.sendMandateForm = exports.answerLoanOffer = exports.getLoanOfferApi = exports.sendGuarantor = exports.getLoanApplicationTerms = exports.sendLoanApplication = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const network_1 = __importStar(require("../../utils/network"));
const initialState = {
    newLoanApplication: null,
    loan_application_id: '',
};
exports.sendLoanApplication = (0, toolkit_1.createAsyncThunk)('loanApplication/apply', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/products/loan-application/create/`, { clientBody: payload, method: 'POST', token: auth.token }, { rejectWithValue });
});
exports.getLoanApplicationTerms = (0, toolkit_1.createAsyncThunk)('get/loanTerms', async (_, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/products/loan/terms/`, { clientBody: {}, method: 'GET', token: auth.token }, { rejectWithValue });
});
exports.sendGuarantor = (0, toolkit_1.createAsyncThunk)('loanApplication/guarantor', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/products/loan-application/${payload.loanApplicationId}/guarantor/create/`, {
        clientBody: {
            title: payload.title,
            first_name: payload.first_name,
            last_name: payload.last_name,
            gender: payload.gender,
            date_of_birth: payload.date_of_birth,
            bvn: payload.bvn,
            email: payload.email,
            phone_number: payload.phone_number,
        },
        method: 'POST',
        token: auth.token,
    }, { rejectWithValue });
});
exports.getLoanOfferApi = (0, toolkit_1.createAsyncThunk)('loanApplication/answer/offer', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/products/loan-application/${payload.loanApplicationId}/offer-letter/`, { clientBody: {}, method: 'GET', token: auth.token }, { rejectWithValue });
});
exports.answerLoanOffer = (0, toolkit_1.createAsyncThunk)('loanApplication/answer/offer', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/products/loan-application/${payload.loanApplicationId}/offer-letter/`, { clientBody: { decision: payload.decision }, method: 'POST', token: auth.token }, { rejectWithValue });
});
exports.sendMandateForm = (0, toolkit_1.createAsyncThunk)('loanApplication/send/mandate', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/products/loan-application/${payload.loanApplicationId}/offer-letter/`, { clientBody: payload.form_data, method: 'POST', token: auth.token }, { rejectWithValue }, { 'Accept': 'application/json', 'Content-Type': 'multipart/form-data' });
});
exports.editLoanApplicationSummary = (0, toolkit_1.createAsyncThunk)('loanApplication/edit', async (payload, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return (0, network_1.default)(`${network_1.BASE_URL}/app/api/v2/products/loan-application/${payload.loanApplicationId}/change/loan-terms/`, {
        clientBody: {
            use_of_proceeds: payload.use_of_proceeds,
            request_amount: payload.request_amount,
            request_tenor_days: payload.request_tenor_days,
            request_repayment_periodicity: payload.request_repayment_periodicity,
            can_provide_cheque: payload.can_provide_cheque,
        },
        method: 'POST',
        token: auth.token,
    }, { rejectWithValue });
});
const loanApplicationSlice = (0, toolkit_1.createSlice)({
    name: 'loanApplication',
    initialState,
    reducers: {
        setInitialState() {
            return initialState;
        },
        setLoanApplicationTerms(state, { payload }) {
            return { ...state, newLoanApplication: payload };
        },
        // Deep link populate reducer with params
        loanApplicationSliceParamsFromDeepLink(state, action) {
            const newState = { ...state };
            newState.loan_application_id = action.payload.loan_application_id;
            return newState;
        },
        // Deep link populate reducer with params
        loanApplicationCreateSliceParamsFromDeepLink(state, { payload }) {
            const newState = {
                ...state,
                newLoanApplication: {
                    use_of_proceeds: parseInt(payload.loanReason, 10),
                    request_amount: parseInt(payload.loanAmount, 10),
                    request_tenor_days: parseInt(payload.tenor, 10),
                    request_repayment_periodicity: parseInt(payload.periodicity, 10),
                    can_provide_cheque: payload.can_provide_cheque,
                },
            };
            return newState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(exports.getLoanApplicationTerms.fulfilled, (state, { payload }) => {
            return { ...state, loanTerms: payload };
        });
    },
});
_a = loanApplicationSlice.actions, exports.setInitialState = _a.setInitialState, exports.setLoanApplicationTerms = _a.setLoanApplicationTerms, exports.loanApplicationSliceParamsFromDeepLink = _a.loanApplicationSliceParamsFromDeepLink, exports.loanApplicationCreateSliceParamsFromDeepLink = _a.loanApplicationCreateSliceParamsFromDeepLink;
exports.default = loanApplicationSlice.reducer;
