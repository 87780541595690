"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const styles = react_native_1.StyleSheet.create({
    graphLabelsPosition: {
        justifyContent: 'center',
        marginLeft: 5,
        bottom: 8,
    },
    amountMargin: {
        marginLeft: 12,
    },
    amounts: {
        marginLeft: react_native_1.Platform.OS === 'web' ? 30 : 0,
    },
    topBarStyle: {
        flexDirection: 'row',
        marginVertical: 20,
        alignContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
    },
});
exports.default = styles;
