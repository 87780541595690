"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const styles = react_native_1.StyleSheet.create({
    logo: {
        maxWidth: 126,
        height: 30,
        resizeMode: 'contain',
        marginVertical: 15,
    },
});
exports.default = styles;
