"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNavigationViewFromLink = exports.LinkingMain = exports.LinkingAuthentication = void 0;
const config_1 = require("../../web/config");
const screenConstants_1 = require("../constants/screenConstants");
const linkingConstants_1 = require("./linkingConstants");
exports.LinkingAuthentication = {
    prefixes: config_1.LINKING_PREFIXES,
    config: {
        screens: {
            // AUTH
            [screenConstants_1.SIGN_IN_SCREEN]: linkingConstants_1.SignInLink,
            [screenConstants_1.ESTIMATE_LOAN_SCREEN]: linkingConstants_1.EstimateLoanLink,
            [screenConstants_1.CREATE_ACCOUNT_SCREEN]: linkingConstants_1.CreateAccountLink,
            [screenConstants_1.RECOVER_PASSWORD_SCREEN]: linkingConstants_1.RecoverPasswordLink,
            [screenConstants_1.RESET_PASSWORD_SCREEN]: linkingConstants_1.ResetPasswordLink,
            [screenConstants_1.NOWNOW_INVITATION_SCREEN]: linkingConstants_1.NowNowInvitationLink,
            // EMAIL VERIFICATION
            [screenConstants_1.EMAIL_CONFIRMATION_PENDING_SCREEN]: linkingConstants_1.EmailConfirmationPendingLink,
            [screenConstants_1.EMAIL_CONFIRMATION_SCREEN]: linkingConstants_1.EmailConfirmationLink,
            // RESEND EMAIL
            [screenConstants_1.RESEND_EMAIL_CONFIRMATION_SCREEN]: linkingConstants_1.ResendEmailConfirmationLink,
            [screenConstants_1.EMAIL_CONFIRMED_SCREEN]: linkingConstants_1.EmailConfirmedLink,
        },
    },
};
exports.LinkingMain = {
    prefixes: config_1.LINKING_PREFIXES,
    config: {
        screens: {
            // EMAIL VERIFICATION
            [screenConstants_1.EMAIL_CONFIRMATION_PENDING_SCREEN]: linkingConstants_1.EmailConfirmationPendingLink,
            [screenConstants_1.EMAIL_CONFIRMATION_SCREEN]: linkingConstants_1.EmailConfirmationLink,
            // RESEND EMAIL
            [screenConstants_1.RESEND_EMAIL_CONFIRMATION_SCREEN]: linkingConstants_1.ResendEmailConfirmationLink,
            [screenConstants_1.EMAIL_CONFIRMED_SCREEN]: linkingConstants_1.EmailConfirmedLink,
            // ONBOARDING
            [screenConstants_1.ONBOARDING_PERSONAL_INFORMATION_SCREEN]: linkingConstants_1.OnboardingPersonalLink,
            [screenConstants_1.ONBOARDING_BUSINESS_INFORMATION_SCREEN]: linkingConstants_1.OnboardingBusinessLink,
            // MAIN
            [screenConstants_1.HOME_SCREEN]: linkingConstants_1.HomeLink,
            [screenConstants_1.LOAN_HOME_SCREEN]: linkingConstants_1.LoanHomeLink,
            [screenConstants_1.PAYMENT_SUMMARY_SCREEN]: linkingConstants_1.PaymentSummaryLink,
            [screenConstants_1.NEW_LOAN_APPLICATION_SCREEN]: linkingConstants_1.NewLoanApplicationScreenLink,
            [screenConstants_1.LOAN_APPLICATION_GUARANTOR_SCREEN]: linkingConstants_1.LoanApplicationGuarantorLink,
            [screenConstants_1.LOAN_APPLICATION_SUMMARY_SCREEN]: linkingConstants_1.LoanApplicationSummaryLink,
            [screenConstants_1.PROFILE_SCREEN]: linkingConstants_1.ProfileLink,
            [screenConstants_1.UPLOAD_CAC_SCREEN]: linkingConstants_1.UploadCacLink,
            [screenConstants_1.PERSONAL_INFORMATION_SCREEN]: linkingConstants_1.PersonalInformationLink,
            [screenConstants_1.PERSONAL_IDENTIFICATION_SCREEN]: linkingConstants_1.PersonalIdentificationLink,
            [screenConstants_1.PERSONAL_ADDRESS_SCREEN]: linkingConstants_1.PersonalAddressLink,
            [screenConstants_1.PERSONAL_CONTACTS_SCREEN]: linkingConstants_1.PersonalContactsLink,
            [screenConstants_1.BUSINESS_INFORMATION_SCREEN]: linkingConstants_1.BusinessInformationLink,
            [screenConstants_1.BUSINESS_ADDRESS_SCREEN]: linkingConstants_1.BusinessAddressLink,
            [screenConstants_1.BUSINESS_ORGANIZATION_SCREEN]: linkingConstants_1.BusinessOrganizationLink,
            [screenConstants_1.BUSINESS_SHAREHOLDERS_SCREEN]: linkingConstants_1.BusinessShareholdersLink,
            [screenConstants_1.FINANCIAL_BANK_INFORMATION_SCREEN]: linkingConstants_1.FinancialBankAccountInformationLink,
            [screenConstants_1.ADD_BANK_ACCOUNT_SCREEN]: linkingConstants_1.AddBankAccountScreenLink,
            [screenConstants_1.CHANGE_PASSWORD_SCREEN]: linkingConstants_1.ChangePasswordLink,
            [screenConstants_1.LOAN_APPLICATION_UPDATE_STATEMENTS_SCREEN]: linkingConstants_1.LoanApplicationUpdateStatementsLink,
            [screenConstants_1.UPLOAD_BANK_STATEMENTS_SCREEN]: linkingConstants_1.UploadBankStatementLink,
            [screenConstants_1.PARTNER_BANK_ACCOUNT_INFO]: linkingConstants_1.PartnerBankAccountInformationLink,
            [screenConstants_1.PARTNER_CONNECT_BANK]: linkingConstants_1.PartnerConnectBankAccountLink,
            [screenConstants_1.PARTNER_OFFER_LETTER]: linkingConstants_1.PartnerOfferLetterLink,
            [screenConstants_1.SURVEY_SCREEN]: linkingConstants_1.SurveyLink,
            [screenConstants_1.ACCEPT_DECLINE_SCREEN]: linkingConstants_1.AcceptDeclineLink,
            [screenConstants_1.MY_BANK_STATEMENT_SCREEN]: linkingConstants_1.MyBankStatementLink,
            // REMITA
            [screenConstants_1.REMITA_CONNECTION_SCREEN]: linkingConstants_1.RemitaConnectionLink,
        },
    },
};
const getNavigationViewFromLink = (link) => {
    const screen = '';
    if (!link)
        return screen;
    if (Object.prototype.hasOwnProperty.call(linkingConstants_1.screens, link)) {
        return linkingConstants_1.screens[link];
    }
    return screen;
};
exports.getNavigationViewFromLink = getNavigationViewFromLink;
